
:root {
  --main: #3B427B
}

  button:disabled {
    opacity: .5;
  }
  .css-1vz7d22-MuiButtonBase-root-MuiMenuItem-root.Mui-selected
  { 
     background-color: transparent; 
}

.MuiMenuItem-root {
  margin-bottom: 5px !important;
}

.MuiPaper-root {
  background-color: white !important;
}

.css-e34unz-MuiTypography-root {
  color: #ffffff !important;
  font-size: small !important
}

*[aria-selected="false"] {
  background-color: transparent;
}

 /* Scroll Bar */
::-webkit-scrollbar {
  width: 5px;
  max-height: 10px;
}

.MuiFormControl-root .css-103b10f-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  padding: 11px !important;
}
 
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
::-webkit-scrollbar-thumb {
  background-color: var(--main);
  border-radius: 3px;
}

 /* HEADER */

.logo {
  display: flex;
  margin: 0px auto 20px auto;
  width: 200px;
  max-width: 100%;
}

/* Toastify CSS Styles */

.Toastify__toast-body {
    font-size: 16px;
}

/* .Toastify__toast--error {
    border: 1px solid #EB5757;
    border-radius: 50px !important;
    background: #FAE1E2 !important;
}
.Toastify__toast--error::after {
  content : url('../assets/images/svg/closeToast.svg');
  position: absolute;
  color: #333333;
  font-size: 15px;
  font-weight: 700;
  left:265px;
  padding-top: 14px !important;
}
.Toastify__toast--error::before {
  content: url("../assets/images/svg/errorToast.svg");
  position:relative; 
  z-index:100000;
  left:12px;
  top:6px;
}
.Toastify__toast--success {
  border: 1px solid #3A9EA5 !important;
  border-radius: 50px !important;
  background: #F0F9FA !important;
}
.Toastify__toast--success::before {
  content: url("../assets/images/svg/successToast.svg");
  position:relative; 
  z-index:100000;
  left:12px;
  top:6px;
}
.Toastify__toast--success::after {
  content : url('../assets/images/svg/closeToast.svg');
  position: absolute;
  color: #333333;
  font-size: 15px;
  font-weight: 700;
  left:265px;
  padding-top: 14px !important;
}
.Toastify__toast--warning {
  border: 1px solid #E78326  !important;
  border-radius: 50px !important;
  background: #FADFC5 !important;
}
.Toastify__toast--warning::before {
  content: url("../assets/images/svg/warnToast.svg");
  position:relative; 
  z-index:100000;
  left:12px;
  top:6px;
}  
.Toastify__toast--warning::after {
  content : url('../assets/images/svg/closeToast.svg');
  position: absolute;
  color: #E78326;
  font-size: 15px;
  font-weight: 700;
  left:265px;
  padding-top: 14px !important;
}
.Toastify__toast-body {
  color: #444C63    ;
  font-size: 16px;
  padding-left: 20px;
  line-height: 20px;
  padding: 0px;
  padding-top: 25px;
  width: 100%;
  font-weight: 400;
  margin-left: 25px !important;
} 
.Toastify__toast > button>  svg {
    display: none;
} */